import { useRef, useEffect, useCallback } from "react";
// import useSound from "use-sound";
import { playBass, playSnare, playHiHat, playCymbal, playTom } from "./audioUtils";

function Playback({ rhythmPattern, isMuted, serverUnixTime, serverStep }) {
  // const [playCymbal1] = useSound(`${process.env.PUBLIC_URL}/tones/Cymbal_808_Tone1.wav`, { interrupt: true });
  // const [playCymbal5] = useSound(`${process.env.PUBLIC_URL}/tones/Cymbal_808_Tone5.wav`, { interrupt: true });
  // const [playCymbal8] = useSound(`${process.env.PUBLIC_URL}/tones/Cymbal_808_Tone8.wav`, { interrupt: true });
  // const [playCymbal11] = useSound(`${process.env.PUBLIC_URL}/tones/Cymbal_808_Tone11.wav`, { interrupt: true });
  // const [playKick1] = useSound(`${process.env.PUBLIC_URL}/tones/Kick_808_Tone1.wav`, { interrupt: true });
  // const [playKick5] = useSound(`${process.env.PUBLIC_URL}/tones/Kick_808_Tone5.wav`, { interrupt: true });
  // const [playKick8] = useSound(`${process.env.PUBLIC_URL}/tones/Kick_808_Tone8.wav`, { interrupt: true });
  // const [playKick11] = useSound(`${process.env.PUBLIC_URL}/tones/Kick_808_Tone11.wav`, { interrupt: true });
  // const [playSnare1] = useSound(`${process.env.PUBLIC_URL}/tones/Snare_808_Tone1.wav`, { interrupt: true });
  // const [playSnare5] = useSound(`${process.env.PUBLIC_URL}/tones/Snare_808_Tone5.wav`, { interrupt: true });
  // const [playSnare8] = useSound(`${process.env.PUBLIC_URL}/tones/Snare_808_Tone8.wav`, { interrupt: true });
  // const [playSnare11] = useSound(`${process.env.PUBLIC_URL}/tones/Snare_808_Tone11.wav`, { interrupt: true });

  const intervalRef = useRef(null);
  const stepRef = useRef(-1);
  const prev = useRef(0);
  const audioCtxRef = useRef(null);

  // const validStep = useCallback((step) => {
  //     return Number.isInteger(step) && step >= 0 && step <= 15;
  // });

  useEffect(() => {
    audioCtxRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioCtxRef.current) {
        audioCtxRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isMuted || !rhythmPattern) {
      clearInterval(intervalRef.current);
      stepRef.current = -1;
      return;
    }

    const playSound = () => {
      // TODO: 仮
      if (stepRef.current === -1 && serverStep === -1) {
        stepRef.current = 0;
      } else if (stepRef.current === -1 && serverStep !== -1) {
        // ローカルの現在時刻
        const localDate = new Date();
        // ローカルの現在時刻をUnixタイムに変換
        const localUnixTime = localDate.getTime();
        // 時刻差
        const timeDifferenceMillis = localUnixTime - serverUnixTime;
        const diff = timeDifferenceMillis / ((60 / 128) * 1000); // 雑
        // setStepDiff(msg.Step + stepDiff);
        // stepRef.current = (serverStep + parseInt(diff) + 1) % 16;
        stepRef.current = (serverStep + parseInt(diff)) % 16;
      } else {
        stepRef.current = (stepRef.current + 1) % 16;
      }
      // if (!validStep(stepRef.current)) {
      //     clearInterval(intervalRef.current);
      //     stepRef.current = -1;
      //     return;
      // }

      const sounds = rhythmPattern[`BeatPosition${stepRef.current}`];

      // debug interval
      const now = new Date().getTime();
      console.log(stepRef.current, serverStep, now - prev.current, serverUnixTime, sounds);
      prev.current = now;

      for (const sound of sounds) {
        switch (sound) {
          case "Cymbal1":
            // playCymbal1();
            playHiHat(audioCtxRef.current, "closed");
            break;
          case "Cymbal5":
            // playCymbal5();
            playHiHat(audioCtxRef.current, "open");
            break;
          case "Cymbal8":
            // playCymbal8();
            playCymbal(audioCtxRef.current, "ride");
            break;
          case "Cymbal11":
            // playCymbal11();
            playCymbal(audioCtxRef.current, "crash");
            break;
          case "Kick1":
            // playKick1();
            playBass(audioCtxRef.current, "acoustic");
            break;
          case "Kick5":
            // playKick5();
            playBass(audioCtxRef.current, "subbass");
            break;
          case "Kick8":
            // playKick8();
            playBass(audioCtxRef.current, "punchy");
            break;
          case "Kick11":
            // playKick11();
            playBass(audioCtxRef.current, "deep");
            break;
          case "Snare1":
            // playSnare1();
            playSnare(audioCtxRef.current, "short");
            break;
          case "Snare5":
            // playSnare5();
            playSnare(audioCtxRef.current, "default");
            break;
          case "Snare8":
            // playSnare8();
            playSnare(audioCtxRef.current, "long");
            break;
          case "Snare11":
            // playSnare11();
            playSnare(audioCtxRef.current, "clap");
            break;
          default:
            break;
        }
      }
    };

    intervalRef.current = setInterval(playSound, (60 / 128) * 1000);

    return () => {
      clearInterval(intervalRef.current);
      stepRef.current = -1;
    };
  }, [isMuted, rhythmPattern, serverUnixTime, serverStep]);
  // }, [validStep, isMuted, rhythmPattern, serverUnixTime, serverStep]);

  return null;
}

export default Playback;

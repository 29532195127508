// バスドラムを生成する関数
export const playBass = (audioCtx, type = "default") => {
  // オシレーターとゲインノードを作成し、バスドラムの音を生成する
  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  // オシレーターの波形タイプを正弦波に設定し、バスドラムの基本的な音色を決定する
  oscillator.type = "sine";
  let frequency = 150; // デフォルトの周波数
  let duration = 0.02; // デフォルトの持続時間
  let fadeOutDuration = 0.15; // デフォルトのフェードアウト時間

  // バスドラムのタイプに応じてパラメータを設定する
  switch (type) {
    case "punchy":
      // パンチのあるバス: 高めの周波数と短い持続時間で、アタック感を強調
      frequency = 200;
      duration = 0.02;
      fadeOutDuration = 0.1;
      break;
    case "deep":
      // 深みのあるバス: 低めの周波数と長い持続時間で、重低音を強調
      frequency = 100;
      duration = 0.05;
      fadeOutDuration = 0.25;
      break;
    case "subbass":
      // サブベースバス: 非常に低い周波数と長い持続時間で、サブベースを強調
      frequency = 60;
      duration = 0.1;
      fadeOutDuration = 0.3;
      break;
    case "acoustic":
      // アコースティックバス: 中程度の周波数と持続時間で、自然なバスサウンドを再現
      frequency = 120;
      duration = 0.05;
      fadeOutDuration = 0.2;
      break;
    default:
      // 定番のバスドラム音色
      frequency = 120;
      duration = 0.02;
      fadeOutDuration = 0.15;
      break;
  }

  // オシレーターの周波数を設定し、指定された持続時間後に急速に減少させる
  oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime);
  oscillator.frequency.exponentialRampToValueAtTime(
    30,
    audioCtx.currentTime + duration
  );

  // ゲイン（音量）を設定し、フェードアウトを開始する
  gainNode.gain.setValueAtTime(1, audioCtx.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(
    0.001,
    audioCtx.currentTime + fadeOutDuration
  );

  // オシレーターをゲインノードに接続し、ゲインノードをオーディオコンテキストの出力に接続する
  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  // オシレーターを開始し、指定されたフェードアウト時間後に停止する
  oscillator.start();
  oscillator.stop(audioCtx.currentTime + fadeOutDuration);
};

// スネアを生成する関数
export const playSnare = (audioCtx, type = "default") => {
  // ホワイトノイズのバッファを作成し、スネアのノイズ部分を生成する
  const bufferSize = audioCtx.sampleRate * 0.2; // 0.2秒のバッファ
  const buffer = audioCtx.createBuffer(1, bufferSize, audioCtx.sampleRate);
  const data = buffer.getChannelData(0);

  // バッファにランダムな値を設定してホワイトノイズを生成する
  for (let i = 0; i < bufferSize; i++) {
    data[i] = Math.random() * 2 - 1;
  }

  // バッファソースノードを作成し、ノイズを再生できるようにする
  const noise = audioCtx.createBufferSource();
  noise.buffer = buffer;

  // ゲインノードを作成し、ノイズの音量を制御する
  const noiseGain = audioCtx.createGain();
  noiseGain.gain.setValueAtTime(0.5, audioCtx.currentTime); // ノイズの初期ゲインを設定

  // スネアのタイプに応じたパラメータを設定する
  let noiseFadeOutTime = 0.2; // デフォルトのノイズのフェードアウト時間
  let initialFrequency = 200; // デフォルトの初期周波数
  let targetFrequency = 100; // デフォルトの目標周波数
  let frequencyRampDuration = 0.1; // デフォルトの周波数ランプ持続時間
  let oscillatorGainValue = 0.7; // デフォルトのオシレーターゲイン

  switch (type) {
    case "short":
      // ショートスネア: 高めの周波数設定、フェードアウト時間は短め
      noiseFadeOutTime = 0.1; // 短めのフェードアウト時間
      initialFrequency = 250; // 高めの初期周波数
      targetFrequency = 150; // 高めの目標周波数
      frequencyRampDuration = 0.05; // 短めのランプ持続時間
      oscillatorGainValue = 0.5; // 低めのオシレーターゲイン
      break;
    case "long":
      // ロングスネア: 長めのディケイで、深みのあるスネアサウンド
      noiseFadeOutTime = 0.3; // 長めのフェードアウト時間
      initialFrequency = 180; // 低めの初期周波数
      targetFrequency = 100; // 低めの目標周波数
      frequencyRampDuration = 0.2; // 長めのランプ持続時間
      oscillatorGainValue = 1; // 高めのオシレーターゲイン
      break;
    case "clap":
      // クラップ風スネア: クラップ（拍手）に似た、短いノイズが特徴
      noiseFadeOutTime = 0.15; // 中程度のフェードアウト時間
      initialFrequency = 200; // 中程度の初期周波数
      targetFrequency = 120; // 中程度の目標周波数
      frequencyRampDuration = 0.1; // 中程度のランプ持続時間
      oscillatorGainValue = 0.8; // 中程度のオシレーターゲイン
      break;
    default:
      break;
  }

  // ノイズのフェードアウトを設定し、自然な減衰を実現する
  noiseGain.gain.exponentialRampToValueAtTime(
    0.001,
    audioCtx.currentTime + noiseFadeOutTime
  );

  // ノイズをゲインノードに接続し、ゲインノードをオーディオコンテキストの出力に接続
  noise.connect(noiseGain);
  noiseGain.connect(audioCtx.destination);

  // ノイズを開始し、終了タイミングを設定
  noise.start();
  noise.stop(audioCtx.currentTime + noiseFadeOutTime);

  // オシレーターを作成してスネアのボディ部分を生成する
  const oscillator = audioCtx.createOscillator();
  const oscillatorGain = audioCtx.createGain();

  // オシレーターの波形タイプを三角波に設定し、スネアの音色を決定する
  oscillator.type = "triangle";

  // オシレーターの初期周波数を設定し、スネアの音の高さを決定する
  oscillator.frequency.setValueAtTime(initialFrequency, audioCtx.currentTime);

  // 周波数をランプダウンし、スネアの音が徐々に低くなるようにする
  oscillator.frequency.exponentialRampToValueAtTime(
    targetFrequency,
    audioCtx.currentTime + frequencyRampDuration
  );

  // オシレーターのゲインを設定し、音量を調整する
  oscillatorGain.gain.setValueAtTime(
    oscillatorGainValue * 0.5,
    audioCtx.currentTime
  );

  // ボディ部分のフェードアウト設定を行い、自然な減衰を実現する
  oscillatorGain.gain.exponentialRampToValueAtTime(
    0.001,
    audioCtx.currentTime + noiseFadeOutTime * 0.5
  );

  // オシレーターをゲインノードに接続し、ゲインノードをオーディオコンテキストの出力に接続
  oscillator.connect(oscillatorGain);
  oscillatorGain.connect(audioCtx.destination);

  // オシレーターを開始し、終了タイミングを設定
  oscillator.start();
  oscillator.stop(audioCtx.currentTime + noiseFadeOutTime * 0.5);
};

// ハイハットを生成する関数
export const playHiHat = (audioCtx, type = "closed") => {
  // ホワイトノイズのバッファを作成し、ハイハットのノイズ部分を生成する
  const bufferSize = audioCtx.sampleRate * 0.5; // 0.5秒のバッファ
  const buffer = audioCtx.createBuffer(1, bufferSize, audioCtx.sampleRate);
  const data = buffer.getChannelData(0);

  // バッファにランダムな値を設定してホワイトノイズを生成する
  for (let i = 0; i < bufferSize; i++) {
    data[i] = Math.random() * 2 - 1;
  }

  // バッファソースノードを作成し、ノイズを再生できるようにする
  const noise = audioCtx.createBufferSource();
  noise.buffer = buffer;

  // フィルタを作成し、ノイズの周波数帯域を調整する
  const filter = audioCtx.createBiquadFilter();
  filter.type = "bandpass";

  // ハイハットのタイプに応じたフィルタとフェードアウトの設定
  let filterFrequency = 10000; // デフォルトの周波数
  let fadeOutDuration = 0.1; // デフォルトのフェードアウト時間

  switch (type) {
    case "open":
      // オープンハイハット: 高い周波数を強調し、少し長めのフェードアウトでシャープな音を作る
      filterFrequency = 10000;
      fadeOutDuration = 0.3;
      break;
    case "closed":
    default:
      // クローズドハイハット: 高い周波数を強調し、非常に短いフェードアウトでタイトな音を作る
      filterFrequency = 10000;
      fadeOutDuration = 0.1;
      break;
  }

  // フィルタの周波数を設定し、ハイハットの音色を決定する
  filter.frequency.setValueAtTime(filterFrequency, audioCtx.currentTime);

  // ゲインノードを作成し、ノイズの音量を制御する
  const gainNode = audioCtx.createGain();
  gainNode.gain.setValueAtTime(1, audioCtx.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(
    0.001,
    audioCtx.currentTime + fadeOutDuration
  );

  // ノイズをフィルタに接続し、フィルタをゲインノードに接続
  noise.connect(filter);
  filter.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  // ノイズを開始し、フェードアウトに応じて停止
  noise.start();
  noise.stop(audioCtx.currentTime + fadeOutDuration);
};

// シンバルを生成する関数
export const playCymbal = (audioCtx, type = "crash") => {
  // ホワイトノイズのバッファを作成し、シンバルのノイズ部分を生成する
  const bufferSize = audioCtx.sampleRate * 2; // 2秒のバッファ
  const buffer = audioCtx.createBuffer(1, bufferSize, audioCtx.sampleRate);
  const data = buffer.getChannelData(0);

  // バッファにランダムな値を設定してホワイトノイズを生成する
  for (let i = 0; i < bufferSize; i++) {
    data[i] = Math.random() * 2 - 1;
  }

  // バッファソースノードを作成し、ノイズを再生できるようにする
  const noise = audioCtx.createBufferSource();
  noise.buffer = buffer;

  // フィルタを作成し、ノイズの周波数帯域を調整する
  const filter = audioCtx.createBiquadFilter();
  filter.type = "bandpass";

  // シンバルのタイプに応じたフィルタとフェードアウトの設定
  let filterFrequency = 8000; // デフォルトの周波数
  let fadeOutDuration = 1.5; // デフォルトのフェードアウト時間

  switch (type) {
    case "ride":
      // ライドシンバル: 中程度の周波数で、長いフェードアウトで持続音を強調
      filterFrequency = 7000;
      fadeOutDuration = 2.0;
      break;
    case "crash":
    default:
      // クラッシュシンバル: 低めの周波数で、適度なフェードアウトでインパクトを強調
      filterFrequency = 5000;
      fadeOutDuration = 1.5;
      break;
  }

  // フィルタの周波数を設定し、シンバルの音色を決定する
  filter.frequency.setValueAtTime(filterFrequency, audioCtx.currentTime);

  // ゲインノードを作成し、ノイズの音量を制御する
  const gainNode = audioCtx.createGain();
  gainNode.gain.setValueAtTime(1, audioCtx.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(
    0.001,
    audioCtx.currentTime + fadeOutDuration
  );

  // ノイズをフィルタに接続し、フィルタをゲインノードに接続
  noise.connect(filter);
  filter.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  // ノイズを開始し、フェードアウトに応じて停止
  noise.start();
  noise.stop(audioCtx.currentTime + fadeOutDuration);
};

// タムを生成する関数
export const playTom = (audioCtx, type = "hi-tom") => {
  // オシレーターとゲインノードを作成し、タムの音を生成する
  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  // オシレーターの波形タイプを正弦波に設定し、タムの基本的な音色を決定する
  oscillator.type = "sine";
  let frequency = 200; // デフォルトの周波数
  let duration = 0.2; // デフォルトの持続時間
  let fadeOutDuration = 0.3; // デフォルトのフェードアウト時間

  // タムのタイプに応じたパラメータを設定する
  switch (type) {
    case "hi-tom":
      // ハイタム: 高めの周波数と短い持続時間で、軽やかな音を強調
      frequency = 250;
      duration = 0.2;
      fadeOutDuration = 0.3;
      break;
    case "low-tom":
      // ロータム: 中程度の周波数とやや長い持続時間で、豊かな音を強調
      frequency = 150;
      duration = 0.3;
      fadeOutDuration = 0.4;
      break;
    case "floor-tom":
      // フロアタム: 低めの周波数と長い持続時間で、重低音を強調
      frequency = 100;
      duration = 0.4;
      fadeOutDuration = 0.5;
      break;
    default:
      // デフォルトはハイタム
      frequency = 250;
      duration = 0.2;
      fadeOutDuration = 0.3;
      break;
  }

  // オシレーターの周波数を設定し、指定された持続時間後に減少させる
  oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime);
  oscillator.frequency.exponentialRampToValueAtTime(
    frequency / 2,
    audioCtx.currentTime + duration
  );

  // ゲイン（音量）を設定し、フェードアウトを開始する
  gainNode.gain.setValueAtTime(1, audioCtx.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(
    0.001,
    audioCtx.currentTime + fadeOutDuration
  );

  // オシレーターをゲインノードに接続し、ゲインノードをオーディオコンテキストの出力に接続する
  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  // オシレーターを開始し、指定されたフェードアウト時間後に停止する
  oscillator.start();
  oscillator.stop(audioCtx.currentTime + fadeOutDuration);
};

import React from "react";
// import { useSTB, useSTBDispatch } from "./STBContext.js";

function MuteButton({ isMuted, toggleIsMuted }) {
  // const state = useSTB();
  // const dispatch = useSTBDispatch();

  return (
    // <button onClick={() => dispatch({ type: "TOGGLE_MUTE" })}>
    <button className="btn btn-primary" onClick={toggleIsMuted}>
      {isMuted ? "消音中" : "再生中"}
    </button>
  );
}

export default MuteButton;

import "./App.css";
import React, { useState, useRef } from "react";
import STBMain from "./stb-client/main.js";

// dummy App.js

function App() {
  const [dataChannelObject, setDataChannelObject] = useState(null);

  const fileInputRef = useRef(null);
  const textareaInputRef = useRef("");

  // const [debugInput, setDebugInput] = useState("");

  const parseAndSetData = (jsonString) => {
    try {
      const parsedInput = JSON.parse(jsonString);
      setDataChannelObject(parsedInput);
    } catch (error) {
      console.error("Invalid JSON input:", error);
      // alert("Invalid JSON format. Please check your input.");
    }
  };
  const handleRun = () => {
    parseAndSetData(textareaInputRef.current.value);
  };

  // const handleDebugInputChange = (e) => {
  //   // debugInputRef.current = e.target.value;
  //   // parseAndSetData(debugInputRef.current);
  //   parseAndSetData(e.target.value);
  // };

  // const handleUseDebugInput = () => {
  //   parseAndSetData(debugInputRef.current);
  // };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      // debugInputRef.current = event.target.result;
      // parseAndSetData(debugInputRef.current);
      // setDebugInput(event.target.result);
      // parseAndSetData(event.target.result);
      textareaInputRef.current.value = event.target.result;
    };
    reader.readAsText(file);
  };

  const dummyData = `{
  "EventID": "XTB_OTOKOGUMI_STB",
  "type": "pattern",
  "BeatPosition0": ["Kick1"],
  "BeatPosition1": ["Kick1"],
  "BeatPosition2": ["Kick1", "Snare5"],
  "BeatPosition3": ["Kick1"],
  "BeatPosition4": ["Kick1"],
  "BeatPosition5": ["Kick1"],
  "BeatPosition6": ["Kick1", "Snare5"],
  "BeatPosition7": ["Kick1"],
  "BeatPosition8": ["Kick1"],
  "BeatPosition9": ["Kick1"],
  "BeatPosition10": ["Kick1", "Snare5"],
  "BeatPosition11": ["Kick1"],
  "BeatPosition12": ["Kick1"],
  "BeatPosition13": ["Kick1"],
  "BeatPosition14": ["Kick1", "Snare5"],
  "BeatPosition15": ["Kick1"]
}`;

  return (
    <div className="App container mx-auto p-4 flex flex-col gap-y-4">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-2xl font-bold leading-7 text-gray-900">
          STB Project データ再生専用ページ
        </h2>
      </div>
      <div className="mt-1 text-sm leading-6 text-gray-600 flex justify-center">
        <ul className="list-disc">
          <li>
            タイマーまわりの制御が未熟なため、音がダブって再生されることがあります。
          </li>
          <li>GitHub Copilot, ChatGPT 4o を使用しています。</li>
          <li>
            ライセンス等が解決できなかったので音源はオシレーターとフィルターで生成しています。
          </li>
        </ul>
      </div>
      <div className="flex flex-row gap-x-4">
        <div className="grow">
          <input
            type="file"
            accept=".json"
            class="file-input file-input-bordered w-full"
            onChange={handleFileInput}
            ref={fileInputRef}
          />
        </div>
        <div className="flex-none">
          <button className="btn btn-primary" onClick={handleRun}>
            実行
          </button>
        </div>
        <div className="flex-none">
          <STBMain
            dataChannelObject={dataChannelObject}
            sendDataChannel={(message) => {
              console.log("sendDataChannel", message);
            }}
            // dataChannelObject={this.state.dataChannelObject}
            // sendDataChannel={this.handleUIInteractionMessageClick.bind(this)}
          />
        </div>
      </div>
      <div>
        <textarea
          rows="30"
          className="textarea textarea-bordered w-full"
          ref={textareaInputRef}
          defaultValue={dummyData}
          // value={debugInput}
          // onBlur={handleDebugInputChange}
          placeholder="Paste JSON here for debugging..."
        />
      </div>
    </div>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import Playback from "./Playback.js";
import MuteButton from "./MuteButton.js";
import { calculateHash } from "./utils.js";

function STBMain({ dataChannelObject, sendDataChannel }) {
  const [isMuted, setIsMuted] = useState(true);
  const [rhythmPattern, setRhythmPattern] = useState({});
  const hashRef = useRef(null);

  const toggleIsMuted = () => {
    setIsMuted((prev) => !prev);
  };

  useEffect(() => {
    if (!dataChannelObject) return;

    const pattern = Object.keys(dataChannelObject).reduce((acc, key) => {
      if (key.startsWith("BeatPosition")) {
        acc[key] = dataChannelObject[key];
      }
      return acc;
    }, {});

    const newHash = calculateHash(pattern);

    if (hashRef.current === newHash) {
      return;
    }
    setRhythmPattern(pattern);
  }, [dataChannelObject]);

  // useEffect(() => {
  //   // console.log(dataChannelObject);
  //   if (!dataChannelObject) return;

  //   const pattern = Object.keys(dataChannelObject).reduce((acc, key) => {
  //     if (key.startsWith("BeatPosition")) {
  //       acc[key] = dataChannelObject[key];
  //     }
  //     return acc;
  //   }, {});

  //   // const steps = {};
  //   // for (let i = 0; i < 16; i++) {
  //   //   steps.push({
  //   //     position: i,
  //   //     sounds: dataChannelObject[`BeatPosition${i}`] || [],
  //   //   });
  //   // }

  //   dispatch({
  //     type: "SET_RHYTHM_PATTERN",
  //     payload: pattern,
  //     hash: calculateHash(pattern),
  //   });
  // }, [dataChannelObject]);

  return (
    <>
      <MuteButton isMuted={isMuted} toggleIsMuted={toggleIsMuted} />
      <Playback rhythmPattern={rhythmPattern} isMuted={isMuted} serverStep={-1} serverUnixTime={-1} />
      {/* <Playback rhythmPattern={state.rhythmPattern} isMuted={state.isMuted} /> */}
      {/* <Playback /> */}
    </>
  );
}

export default STBMain;
